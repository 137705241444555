import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import Link from 'gatsby-link'
import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, Span } from "../components/Core";
import Head from "../components/head";

const NavStyled = styled(Nav)`
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #f7f7fb;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.dark} !important;
    &:hover,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.secondary} !important;
    }
  }
`;

const Terms = () => {
  return (
    <>
     <Head title="FAQ Dermamina" image="/images/dermamina-home.jpg"  description="experts in Anti-wrinkle treatments LondonLondon" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>
      <PageWrapper headerDark footerLight>
       
        <Section>
        <div className="pt-5"></div>
          <Container>
          <Title>Terms & Conditions</Title>
            <Tab.Container id="left-tabs-example" defaultActiveKey="general">
              <Row>
                
                <Col className="mb-5 mb-md-0">
                    <p>

                    <b>Information</b> <br /> <br />

All patients are required to provide contact details; including full name, date of birth, address, telephone and email in order to secure an appointment.<br /> <br />

All clients will complete a Medical History form, Treatment Consent form and COVID Consent form at their first appointment, this is necessary to inform the consultation and treatment planning process.<br /> <br />

All information will be treated as confidential and protected in accordance with Data Protection legislation.<br /> <br />

Patient information will not be shared with third parties without written permissions, and you will not receive unsolicited information from us.<br /> <br />

You may choose to remove yourself from our mailing list at any time, by unsubscribing.<br /> <br />


<b>Appointments </b><br /> <br />

A face to face consultation is provided with every treatment booked<br /> <br />

Please provide 48 hours’ notice if you need to cancel or reschedule your appointment, so that we may make best use of our appointment diary. If you do not attend a booked appointment, and fail to advise us within 48 hours, a £50 non-refundable booking fee will be charged.  <br /> <br />

If a consultation was provided during the appointment, there will be a charge of the consultation fee, as shown on the price list. This will be payable at the clinic or deducted from the deposit made. <br /> <br />

Routine review appointments are offered after treatment as a courtesy. If you are unable to attend the review appointment at 2 weeks, no additional treatment or ‘top up’ is provided once the review period of 2 weeks has passed.<br /> <br />

New patients are seen for consultation and assessment; except in exceptional circumstances, with prior arrangement, treatment is not provided on the first visit.<br /> <br />

•	To reduce the contact time with your practitioner we ask that you are prepared for your treatment:<br /> <br />
o	Face Treatments: please ensure that you attend the appointment without make or all make-up should be removed prior to the appointment.<br /> <br />
o	Laser hair removal: area should be shaved and hair removed prior to treatment<br /> <br />

Prior to your appointment, we will inform you of any preparation required for your treatment. If you do not follow the guidance this may result in cancellation of your appointment, reduced treatment time, or additional fees being charged.<br /> <br />

Dermamina will endeavour to ensure that your appointment runs on time. However, should we need to cancel your appointment at short notice, we will make every effort to contact you in advance. All treatments cancelled by Dermamina will be rebooked as soon as convenient for our client.<br /> <br />

If you do not attend your appointment with no attempt made to let us know, this will result in a 100% charge or forfeiting your treatment.<br /> <br />

Late arrival to your appointment may result in a reduction in your treatment time, forfeiting your appointment or a charge.<br /> <br />

<b>Liability</b><br /> <br />

Clients will be required to read and sign the relevant consent forms prior to treatment. We are unable to provide any treatment without your written consent.<br /> <br />

It is the client’s responsibility to ensure that they provide Dermamina with all relevant medical history and medication details prior to each treatment. Dermamina will not be liable for any damage that occurs as a result of the client’s failure to disclose such details.<br /> <br />

The client agrees to comply with all instructions and/or recommendations given to them by, or on behalf of, Dermamina regarding the care of a treated area.<br /> <br />


<b>Children </b><br /> <br />

We do not treat children or young adults under the age of 18.<br /> <br />
Please do not bring children to the clinic unless they are old enough to be left unsupervised. <br /> <br />
Children will not be allowed to accompany you into the treatment rooms.<br /> <br />

<b>Treatment Suitability</b><br /> <br />

•	Before carrying out any treatment, we will assess whether the treatment is suitable for you, or likely to be successful. If not, we will inform you as to the reasons why. You will only be liable for the cost of the initial consultation, where applicable.<br /> <br />
•	It is your responsibility to ensure that you provide Dermamina with all the relevant medical details prior to each treatment. All the forms will be emailed to you beforehand. This is to ensure that forms are completed, so you are fully informed and prepared before the appointment. <br /> <br />





<b>Payment</b><br /> <br />

You will be advised of the full costs of any treatment plan proposed and agreed, including that of any maintenance treatment, before any treatment is undertaken.<br /> <br />

Payment is taken, in full after the treatment.<br /> <br /> 

The clinic accepts cash, or major debit and credit cards.<br /> <br />


<b>Refunds</b><br /> <br />

All of the treatments provided at Dermamina are non-refundable once the treatment has been undertaken.<br /> <br /> 
Treatment packages at Dermamina are non-refundable under any circumstances.<br /> <br /> 

<b>Feedback</b><br /> <br />

Any feedback is much appreciated, both positive and negative. Feedback is used to review and improve quality of service. You may submit feedback verbally, via email, via our website.<br /> <br />

<b>Complaints</b><br /> <br />

If you have a complaint please inform us as soon as possible. An appointment will be made for you to be seen. A copy of our Complaints Policy is available on request.  <br /> <br />

At Dermamina we endeavour to treat all our patients professionally, compassionately and fairly. If, however, you have a complaint please inform us as soon as possible. A copy of our Complaints Policy is available on request. <br /> <br />

If you have any issue in relation to your visit, you are entitled to lodge a complaint, either in person, by telephone or in writing. If you require assistance with making your complaint, we will be pleased to help and support you through the process.<br /> <br />

<b>Cancellation Policy</b><br /> <br />

Our cancellation policy is in place to ensure that we are able to maximise our appointment availability for all of their clients. This ensures that we are able to offer a cancelled appointment time to a client who needs it.<br /> <br />

Should you need to cancel or reschedule, we kindly ask that you call us at least 48 hours before your appointment.<br /> <br />

Any cancellation within 48 hours will result in your pre-paid treatment or deposit being forfeited.<br /> <br />

<b>Packages:</b><br /> <br />

Package expiry: All treatments from a course must be taken within 24 months (2 years) of the date of purchase; any treatments left unused after 24 months will be automatically redeemed and a new package will need to be purchased.<br /> <br />


<b>MEDICAL DISCLAIMERS</b><br /> <br />

(a) You agree that the Content is of a general nature and does not constitute professional medical or healthcare advice, diagnosis, or recommendation of treatment and are not intended to, nor should they be used to, replace professional medical advice. In no circumstances should any Content be relied upon without independent consideration and confirmation by a qualified medical practitioner.<br /> <br />

(b) Subject to applicable consumer law, we make no representations or warranties with respect to any treatment, action, suitability or application of medication or preparation by any person whether based on the Content or not. In no circumstances will we be liable for any direct, indirect, consequential, special, exemplary or other damages arising from the same, save for instances of our fraud, negligence and wilful misconduct.<br /> <br />

(c) Always seek the advice of your doctor or other qualified healthcare provider with any questions you may have regarding a medical condition or treatment before undertaking a treatment regimen.<br /> <br />

     
                    </p>
                </ Col>
              
              </Row>
            </Tab.Container>
          </Container>
        </Section>
      </PageWrapper>
      
    </>
  );
};
export default Terms;
